@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.bannerContainer {
  height: 570px;
  background-color: $tp-pink-bulletin-pink;
  display: flex;
  flex-direction: column;
  @include laptop-bp() {
    min-height: 1000px;
    flex-direction: row;
  }

  .textSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      padding: 48px 16px;
      @include heading3;
      font-size: 42px;
      @include laptop-bp() {
        font-size: 50px;
        padding: 32px;
      }
    }

    .titleAndDescription {
      padding-bottom: 24px;
      padding-inline: 16px;
      @include laptop-bp() {
        width: 404px;
        padding: 48px 32px;
      }

      .date {
        @include paragraph-XS($font-manrope-normal);
        font-weight: 600;
        letter-spacing: 2.2px;
      }
      .title {
        @include heading5;
        font-size: 26px;
        margin-block: 12px 16px;
      }
      .description {
        @include subHeading5($font-manrope-normal);
        font-weight: 500;
        align-self: stretch;
        margin-bottom: 24px;
      }
      .linkWrapper {
        display: flex;
        gap: 4px;
        .link {
          text-decoration: underline;
          width: fit-content;
          color: #333333;
          @include subHeading4($font-manrope-normal);
          letter-spacing: 2.2px;
          font-weight: 600;
          font-size: 11px;
          text-align: left;
        }
      }
    }
  }
  .imageSection {
    flex: 2;
    @include laptop-bp() {
      flex: 1;
    }
    position: relative;
    .image {
      object-fit: cover;
      transition: 0.4s;
      &:hover {
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
}
