@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';
@import '../../../../styles/mixins/layout-mixins';

.banner {
  margin-bottom: 55px;
  @include desktop-bp {
    margin-bottom: 0px;
  }
}
.categoriesContainer {
  background-color: $tp-pink-bulletin-pink;
  @include desktop-bp {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .categories {
    display: flex;
    max-width: $content-max-width;
    flex-direction: row;
    column-gap: 40px;
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    flex-wrap: nowrap;
    overflow: scroll;

    @include desktop-bp {
      flex-wrap: wrap;
      overflow: hidden;
      padding: 0 30px;
    }

    .category {
      @include paragraph-XS(manrope);
      text-transform: uppercase;
      letter-spacing: 2.2px;
      font-weight: 500;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
      flex-shrink: 0;

      @include desktop-bp {
        padding: 19px 0;
      }

      &__selected {
        color: rgba(0, 0, 0, 1);
        border-bottom: 1px solid black;
      }
    }
  }
}
.pinknBulletinWrapper {
  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;

  .articlesContainer {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 8px;
    row-gap: 50px;
    padding: 15px;
    cursor: pointer;
    background-color: $tp-pink-bulletin-pink;

    @include desktop-bp {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 30px;
    }

    .articleCard {
      display: flex;
      flex-direction: column;

      &__imageWrapper {
        position: relative;
        &::before {
          float: left;
          padding-top: (19 / 23) * 100%;
          content: '';
        }

        &::after {
          display: block;
          content: '';
          clear: both;
        }

        &__image {
          transition: all 0.2s ease-in-out;
          transition: 0.2s;
          object-fit: cover;
        }
        &__image:hover {
          transform: scale(1.1);
        }
      }

      &__date {
        @include paragraph-XS(manrope);
        margin-top: 16px;
        letter-spacing: 2.2px;
        font-weight: 500;
        @include desktop-bp {
          margin-top: 24px;
        }
      }

      &__title {
        @include subHeading2('Libertanius Serif');
        font-weight: 400;
        margin-top: 16px;
      }

      &__description {
        @include paragraph-S(manrope);
        letter-spacing: 0.28px;
        font-weight: 500;
      }

      &__readmore {
        @include paragraph-XS(manrope);
        text-decoration: underline;
        text-transform: uppercase;
        text-align: left;
        margin-top: 16px;
        letter-spacing: 2.2px;
        font-weight: 500;

        &__arrow {
          display: inline-block;
          height: 13px;
          padding-left: 10px;
        }
      }
    }
  }

  .fetchMore {
    background-color: $tp-pink-bulletin-pink;
    text-align: center;
    @include subHeading6(manrope);
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
    padding: 40px 10px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 2.2px;
    text-decoration: underline;
  }

  .fetchMore::after {
    content: '\00a0\00a0+';
  }
}
